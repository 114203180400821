var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 mt-3"},[(_vm.$route.name == 'manufacturingWithdrawalsCreate')?_c('addHeader',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.AddAWithdrawalWithOperations'),"description":_vm.$t('manufacturingWithdrawals.form.FromHereYouCanAddAWithdrawalOfOperations')}}):_vm._e(),(_vm.$route.name == 'manufacturingWithdrawalsEdit')?_c('addHeader',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.editOperation'),"description":_vm.$t('manufacturingWithdrawals.form.fromHereYouEditOperation')}}):_vm._e(),_c('div',{staticClass:"mb-4 row"},[_c('dvider',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.basicInformation'),"description":_vm.$t('manufacturingWithdrawals.form.mainIformation'),"noLine":"true"}}),(_vm.$route.name == 'manufacturingWithdrawalsCreate')?_c('connectionInput',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.NumberingTheProcess'),"group":_vm.$t('manufacturingWithdrawals.form.Groups'),"disabled":_vm.$user.admin ||
            _vm.$user.role.manufacturing_operations_edit_item
              ? false
              : true,"values":_vm.$database.invoiceGroups,"hasErorr":_vm.errors.invoice_group,"error":_vm.$t('manufacturingWithdrawals.form.NumberingIsRequired')},model:{value:(_vm.item.invoice_group),callback:function ($$v) {_vm.$set(_vm.item, "invoice_group", $$v)},expression:"item.invoice_group"}}):_vm._e(),_c('subFileInput',{attrs:{"title":_vm.$t('invoices.form.invoiceFile'),"file":_vm.item.file,"path":'invoices',"hasErorr":_vm.errors.file,"error":_vm.$t('allerts.ChooseASuitableFile')},model:{value:(_vm.item.file),callback:function ($$v) {_vm.$set(_vm.item, "file", $$v)},expression:"item.file"}}),_c('formInput',{attrs:{"title":_vm.$t('salesPayments.form.reference'),"maxlength":"255"},model:{value:(_vm.item.refrance),callback:function ($$v) {_vm.$set(_vm.item, "refrance", $$v)},expression:"item.refrance"}}),(_vm.$route.name == 'manufacturingWithdrawalsEdit')?_c('formInput',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.NumberingTheProcess'),"disabled":true},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}):_vm._e(),_c('formInput',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.reference'),"disabled":true},model:{value:(_vm.item.manufacturing_operation_code),callback:function ($$v) {_vm.$set(_vm.item, "manufacturing_operation_code", $$v)},expression:"item.manufacturing_operation_code"}}),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(_vm._s(_vm.$t('manufacturingWithdrawals.form.date')))]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100 to-left"},[_vm._m(0),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('manufacturingWithdrawals.form.ChooseTimeAndDate'),"button-now-translation":_vm.$t('manufacturingWithdrawals.form.Now'),"disabled":_vm.$user.admin ||
                  _vm.$user.role.store_withdrawals_edit_item
                    ? false
                    : true,"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)])]),_c('connectionInput',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.ProductStore'),"group":_vm.$t('manufacturingWithdrawals.form.stores'),"values":_vm.$database.stores,"hasErorr":_vm.errors.store_id,"error":_vm.$t('manufacturingWithdrawals.form.ThisFieldIsRequired')},model:{value:(_vm.item.store_id),callback:function ($$v) {_vm.$set(_vm.item, "store_id", $$v)},expression:"item.store_id"}}),_c('monyInput',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.price'),"disabled":true},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}}),_c('selectInput',{attrs:{"title":_vm.$t('invoices.form.Situation'),"values":[
            { name: _vm.$t('invoices.draft'), value: 0 },
            { name: _vm.$t('invoices.approved'), value: 1 }
          ]},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}}),_c('dvider',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.ProductsInformation'),"description":_vm.$t('manufacturingWithdrawals.form.BillProductInformation')}}),_c('productsTable',{staticClass:"col-12"}),_c('dvider',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.PaymentDetails'),"description":_vm.$t('manufacturingWithdrawals.form.additionalOperationInformation')}}),_c('formTextarea',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.thatAbout')},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('formTextarea',{attrs:{"title":_vm.$t('manufacturingWithdrawals.form.note')},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}}),_c('hr',{staticClass:"mt-5 mb-5 col-12"}),(
            _vm.$route.name == 'manufacturingWithdrawalsCreate' && !_vm.$parent.stopEdit
          )?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('manufacturingWithdrawals.form.AddAProcess'))+" ")]):_vm._e(),(_vm.$route.name == 'manufacturingWithdrawalsEdit' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('manufacturingWithdrawals.form.ProcessModification'))+" ")]):_vm._e(),(_vm.$parent.stopEdit)?_c('loading'):_vm._e()],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])}]

export { render, staticRenderFns }